import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { Flex, Title, Label, Box, Grid, Text } from "../components/system"
import { GatsbyImage } from "gatsby-plugin-image"
import Video from "../components/Video"
import Blocks from "../components/Blocks"
import SEO from "../components/seo"

const About = ({ data }) => {
  const { label, title, video, team, _rawContent } = data.sanityAbout
  return (
    <Layout dark>
      <SEO
        title="About us"
        description="Ilana means pathway in Yoruba and that's exactly what we exist to do, we are a store for black owned fashion brands and a community for fashion designers to gain expertise, connect and collaborate with other designers. Creating a pathway for emerging talent."
      />
      <Flex flexDirection="column" pt={["40%", "12%"]}>
        <Label fontSize={1} color="faded">
          {label}
        </Label>
        <Title fontSize={[6, 7]} color="primary">
          {title}
        </Title>
      </Flex>
      <Box mx={[0, 6]} mt={4}>
        <Video url={video.asset.url} />
      </Box>

      <Box my={(6, "12%")}>
        <Blocks
          textAlign="center"
          px={[3, "15%"]}
          color="primary"
          data={_rawContent}
        />
      </Box>

      <Grid
        mx={[3, 6]}
        mt={4}
        mb={6}
        gridGap={50}
        gridTemplateColumns={["1fr", "1fr 1fr", "1fr 1fr 1fr"]}
      >
        {team.map((t, key) => (
          <Box key={key}>
            <GatsbyImage image={t.avatar.asset.gatsbyImageData} />
            <Flex flexDirection="column" alignItems="flex-start" mt={3}>
              <Label color="primary">{t.name}</Label>
              <Label color="secondary" down>
                {t.role}
              </Label>
              <Text textAlign="left" fontSize={1} mt={2} color="shadow">
                {t.bio}
              </Text>
            </Flex>
          </Box>
        ))}
      </Grid>
    </Layout>
  )
}

export default About

export const pageQuery = graphql`
  query about {
    sanityAbout {
      label
      title
      video {
        asset {
          url
        }
      }
      _rawContent
      team {
        name
        role
        avatar {
          asset {
            gatsbyImageData(aspectRatio: 1)
          }
        }
        bio
      }
    }
  }
`
